<template>
  <van-list v-model="loading" :finished="finished" loading-text="努力加载中" @load="onLoad">
    <div v-for="item in list" :key="item.milepostId" class="card" @click="onClick(item)">
      <div class="card-header">
        <span>{{ formatTime(item.resultDate) }}</span>
      </div>

      <div class="detail">{{ item.detail }}</div>
      <div class="card-footer">
        <span>{{ item.stage === 2 ? "一阶段" : item.stage === 3 ? "二阶段" : "" }}</span>
        <div>
          <span>{{ item.operateUserName }}</span>
          <span style="margin-left: 7px">{{ dayjs(item.updatedTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </div>
      </div>
    </div>
    <van-empty v-if="list.length === 0 && finished"/>
  </van-list>
</template>

<script>
import dayjs from "dayjs";
import {reviewSummaryList} from "@/api/hoss";

export default {
  data() {
    return {
      loading: false,
      finished: false,

      list: [],
      page: 0,
    };
  },
  created() {
    const {clientId} = this.$route.query;
    this.clientId = clientId;
  },
  methods: {
    dayjs,
    // 破冰记录列表
    async getReviewSummaryList() {
      const {clientId, page} = this;
      const {value} = await reviewSummaryList({clientId, page});
      this.list.push(...value.list);
      this.finished = value.lastPage;
      this.loading = false;
    },
    onLoad() {
      this.page++;
      this.getReviewSummaryList();
    },
    formatTime(t) {
      return dayjs(t).format("YYYY-MM-DD");
    },
    onClick({id}) {
      this.$router.push(`/hoss/reviewSummary?id=${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  font-size: 12px;
  background-color: var(--blueCardBackgroundColor);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  .detail {
    color: var(--subTitleColor);
    line-height: 1.3em;
    height: 3.9em;
    margin: 5px 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--mainColor);
  }
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  span {
    margin-right: 10px;
  }
}

.van-empty {
  width: 180px;
  height: 180px;
  margin: 0 auto;
}
</style>
